import Accent from "../Accent/Accent";
import "./Notification.css";

interface Props {
    title: string | Array<string | JSX.Element>;
    desc: string | Array<string | JSX.Element>;
}

export default function Notification({ title, desc }: Props) {
    return <div className="notification flex flex-col align-center text-center">
        <Accent />
        <h2 className="main-title">{title}</h2>
        <p className="desc">{desc}</p>
    </div>
}