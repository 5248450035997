import { ButtonType } from "../../enums/ButtonType";
import "./Button.css";

interface Props {
    onClick: () => void;
    type: ButtonType;
    disabled?: boolean;
    children: JSX.Element[] | string;
}

export default function Button({ onClick, type, disabled, children }: Props) {
    const classNames = type === ButtonType.Main ? "main" : type === ButtonType.MinorRed ? "minor-red" : "minor-white";

    return <button className={classNames} onClick={onClick} disabled={disabled}>
        {children}
    </button>

}