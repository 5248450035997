import { Translation } from "../interfaces/Translation";

export enum TranslationsKey {
    CallForHelp,
    ListOfFirstAiders,
    ListOfFirstAiders_Explanation,
    NameAndSurname,
    Phone,
    Email,
    Available,
    ContactingFirstAiders,
    ContactingFirstAiders_Hint,
    CallEmergencyServices,
    Cancel,
    TryAgain,
    NoResponse_Loader,
    NoResponse_Title,
    NoResponse_Content,
    FirstAiderComing,
    WhatToDoWhileWaitingForFirstAider,
    Ok,
    NoFirstAiders,
    NoAiders_ErrorTitle,
    NoAiders_ErrorContent,
    NoInternetConnection_Title,
    NoInternetConnection_Desc,
    LastRefreshedDate,
};

export const translations: Record<TranslationsKey, Translation> = {
    [TranslationsKey.CallForHelp]: { en: "Call for help", pl: "Zadzwoń po pomoc" },
    [TranslationsKey.ListOfFirstAiders]: { en: "List of First aiders", pl: "Lista ratowników pierwszej pomocy" },
    [TranslationsKey.ListOfFirstAiders_Explanation]: { en: "People chosen to provide first aid", pl: "Osoby wyznaczone do udzielania pierwszej pomocy" },
    [TranslationsKey.NameAndSurname]: { en: "Name and surname", pl: "Imię i nazwisko" },
    [TranslationsKey.Phone]: { en: "Phone", pl: "Telefon" },
    [TranslationsKey.Email]: { en: "E-mail", pl: "E-mail" },
    [TranslationsKey.Available]: { en: "Available", pl: "Dostępny" },
    [TranslationsKey.ContactingFirstAiders]: { en: "Contacting first aiders", pl: "Kontaktujemy się z ratownikami pierwszej pomocy" },
    [TranslationsKey.ContactingFirstAiders_Hint]: { en: ["REMEMBER - ", <b>this is not a call for Ambulance</b>, ". If you need to contact hospital you have to do this on your own."], pl: ["PAMIĘTAJ - ", <b>to nie jest telefon wzywający karetkę pogotowia</b>, ". Jeśli potrzebujesz skontaktować się ze szpitalem, musisz zrobić to samodzielnie."] },
    [TranslationsKey.CallEmergencyServices]: { en: "Call Emergency Services", pl: "Zadzwoń po służby ratownicze" },
    [TranslationsKey.Cancel]: { en: "Cancel", pl: "Anuluj" },
    [TranslationsKey.TryAgain]: { en: "Try again", pl: "Spróbuj ponownie" },
    [TranslationsKey.NoResponse_Loader]: { en: "Please go to the floor reception for help or contact first aiders directly.", pl: "Proszę, udaj się po pomoc do recepcji albo bezpośrednio skontaktuj z ratownikami pierwszej pomocy." },
    [TranslationsKey.NoResponse_Title]: { en: "No one is responding!", pl: "Nikt nie odpowiada!" },
    [TranslationsKey.NoResponse_Content]: {
        en: ["Please go to the ", <b>ground floor</b>, " reception, alternatively ", <b>contact with first aiders directly</b>, "."],
        pl: ["Proszę, udaj się do recepcji na ", <b>parterze</b>, " albo ", <b>bezpośrednio skontaktuj się z ratownikami pierwszej pomocy</b>, "."]
    },
    [TranslationsKey.FirstAiderComing]: { en: "First aider is on their way", pl: "Ratownik pierwszej pomocy jest w drodze" },
    [TranslationsKey.WhatToDoWhileWaitingForFirstAider]: { en: "Please wait in the kitchen. When first aider will arrive guide him to the accident spot.", pl: "Proszę, zaczekaj w kuchni. Kiedy przybędzie ratownik pierwszej pomocy, wskaż mu drogę na miejsce wypadku." },
    [TranslationsKey.Ok]: { en: "ok", pl: "ok" },
    [TranslationsKey.NoFirstAiders]: {
        en: ["No first aiders found. In case of emergency please go to the ", <b>ground floor</b>, " reception, call ", <b>112</b>, " or ", <b>999</b>, "."],
        pl: ["Brak dostępnych ratowników pierwszej pomocy. W razie wypadku proszę udaj się do recepcji na ", <b>parterze</b>, " zadzwoń pod numer ", <b>112</b>, " albo ", <b>999</b>, "."]
    },
    [TranslationsKey.NoAiders_ErrorTitle]: { en: "No first aiders found", pl: "Brak dostępnych ratowników pierwszej pomocy" },
    [TranslationsKey.NoAiders_ErrorContent]: { en: "In case of emergency please go to the \bground floor\b reception, alternatively \b6 floor\b reception.", pl: "W razie wypadku proszę udaj się do recepcji na \bparterze\b, ewentualnie do recepcji na \b6. pętrze\b." },
    [TranslationsKey.NoInternetConnection_Title]: {en: "No internet connection", pl: "Brak połączenia z internetem"},
    [TranslationsKey.NoInternetConnection_Desc]: {
        en: ["In case of emergency please go to the ", <b>ground floor</b>, " reception, call ", <b>112</b>, " or ", <b>999</b>, "."],
        pl: ["W razie wypadku proszę udaj się do recepcji na ", <b>parterze</b>, " zadzwoń pod numer ", <b>112</b>, " albo ", <b>999</b>, "."]
    },
    [TranslationsKey.LastRefreshedDate]: {en: "Last refreshed date", pl: "Data ostatniego odświeżenia"},
}