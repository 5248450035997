import "./LanguageToggle.css";
import { Language } from "../../enums/Language";
import { useLanguage } from "../../customHooks/useLanguage";
import { useChangeLanguage } from "../../customHooks/useLanguageChange";

export default function LanguageToggle() {
    const currLanguage = useLanguage();
    const changeLanguage = useChangeLanguage();
    const englishChosen = currLanguage === Language.English;

    return (
        <div className="language-toggle">
            <input type="radio"
                className="toggle-radio"
                id="polish-choice"
                name="language"
                value={Language.Polish}
                checked={!englishChosen}
                onChange={() => changeLanguage(Language.Polish)} />
            <label
                className={`toggle-label left${englishChosen ? "" : " selected"}`}
                htmlFor="polish-choice">
                PL
            </label>
            <input type="radio"
                className="toggle-radio"
                id="english-choice"
                name="language"
                value={Language.English}
                checked={englishChosen}
                onChange={() => changeLanguage(Language.English)} />
            <label
                className={`toggle-label right${!englishChosen ? "" : " selected"}`}
                htmlFor="english-choice">
                ENG
            </label>
        </div>
    )
}