import { useEffect, useState } from "react";
import { FirstAider } from "../interfaces/FirstAider";
import { useMsal } from "@azure/msal-react";
import { tableStorageRequest } from "../authConfig";
import { getTableClient } from "../azureManagement";
import iterateOverEntities from "../utils/iterateOverEntities";
import isDateToday from "../utils/isDateToday";
import { sortFirstAidersList } from "../utils/sortFirstAidersList";
import environment from "../environments";
import { handleError } from "../utils/handleError";
import { getFirstAidersListFromLocalStorage, getLastRefreshedDateFromLocalStorage, saveFirstAidersListInfoToLocalStorage, } from "../utils/firstAidersLocalStorage";

export function useFirstAidersList(): [FirstAider[], (null | Date)] {
    const [firstAidersList, setFirstAidersList] = useState<Array<FirstAider>>([]);
    const [lastRefreshedDate, setLastRefreshedDate] = useState<Date | null>(null);
    const { instance, accounts } = useMsal();

    useEffect(() => {
        let ignore = false;

        async function fetchFAList() {

            const request = {
                ...tableStorageRequest,
                account: accounts[0]
            }

            try {
                const authResult = await instance.acquireTokenSilent(request)
                const accessToken = authResult.accessToken;
                const tableClient = await getTableClient(accessToken, environment.storageInformation.tableName);
                const tableData = await iterateOverEntities(tableClient);
                const todayFAList = tableData.filter(fa => {
                    return isDateToday(fa.timestamp);
                });
                const sortedTodayFAList = sortFirstAidersList(todayFAList);
                if (!ignore) {
                    saveFirstAidersListInfoToLocalStorage(sortedTodayFAList, new Date());
                    setFirstAidersList(getFirstAidersListFromLocalStorage());
                    setLastRefreshedDate(getLastRefreshedDateFromLocalStorage());
                }
            } catch (error) {
                handleError(error, instance, request);
                const staleFirstAidersList = getFirstAidersListFromLocalStorage().map(fa => ({ ...fa, availability: false }));
                setFirstAidersList(staleFirstAidersList);
                setLastRefreshedDate(getLastRefreshedDateFromLocalStorage());
            }

        }

        fetchFAList();
        const intervalId = setInterval(fetchFAList, 60000);

        return () => {
            clearInterval(intervalId);
            ignore = true;
        };

    }, [accounts, instance]);

    const res: [FirstAider[], (null | Date)] = [firstAidersList, lastRefreshedDate];
    return res;
}