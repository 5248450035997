import { useEffect, useState } from "react";
import { FirstAider } from "../../interfaces/FirstAider";
import "./FirstAiderCard.css";
import { useMsal } from "@azure/msal-react";
import { photoRequest } from '../../authConfig';
import UserInCircle from "../../assets/icons/UserInCircle.svg";

interface Props {
    firstAider: FirstAider;
}

export default function FirstAiderCard({ firstAider }: Props) {
    const { instance, accounts } = useMsal();
    const [firstAiderPhoto, setFirstAiderPhoto] = useState<string>('');

    //TODO: create custom hook & rewrite with async/await
    useEffect(() => {
        let ignore = false;

        if (instance && accounts[0]) {
            instance.acquireTokenSilent({
                ...photoRequest,
                account: accounts[0],
            })
                .then(response => response.accessToken)
                .then(accessToken => fetch(`https://graph.microsoft.com/v1.0/users/${firstAider.email}/photos/240x240/$value`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${accessToken}`,
                        },
                        method: "GET"
                    })
                )
                .then(res => {
                    if (!ignore) {

                        if (res.status === 404) {
                            setFirstAiderPhoto('');
                            return;
                        }

                        res.blob()
                            .then(res => {
                                const photoUrl = URL.createObjectURL(res);
                                setFirstAiderPhoto(photoUrl);
                            })
                    }
                })
                .catch(err => console.error("err", err))
        }

        return () => {
            ignore = true;
        };

    }, [instance, accounts, firstAider.email])


    return <div className="first-aider-card flex flex-row align-center grow mt-60 mb-72">
        <img
            className={`fa-photo ${firstAiderPhoto ? "border-circle" : ""}`}
            src={firstAiderPhoto ? firstAiderPhoto : UserInCircle}
            alt="first aider that is on their way" />
        <ul>
            <li className="name">{firstAider.name} {firstAider.surname}</li>
            {firstAider.phone && <li>{firstAider.phone}</li>}
            <li>{firstAider.email}</li>
        </ul>
    </div>
}