import { FirstAider } from "../interfaces/FirstAider";

export function saveFirstAidersListInfoToLocalStorage(firstAiders: FirstAider[], date: Date) {
    localStorage.setItem("firstAidersList", JSON.stringify(firstAiders));
    localStorage.setItem("lastRefreshedDate", JSON.stringify(date.toString()));
}

export function getFirstAidersListFromLocalStorage() {
    const firstAiders = localStorage.getItem("firstAidersList");
    if(!firstAiders) return [];

    const firstAidersParsed: FirstAider[] = JSON.parse(firstAiders);
    return firstAidersParsed;
}

export function getLastRefreshedDateFromLocalStorage() {
    const lastRefreshedDate = localStorage.getItem("lastRefreshedDate");
    if(!lastRefreshedDate) return null;

    const lastRefreshedDateParsed = new Date(JSON.parse(lastRefreshedDate));
    return lastRefreshedDateParsed;
}