import { LogLevel } from "@azure/msal-browser";

export const environment = {
    production: true,
    msal: {
        auth: {
            clientId: "e8684fa0-34c7-4551-a8b3-3deb95152033",
            authority: "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
            redirectUri: "https://firstaiders.pl.abb.com",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false, // set to "true" if issues on IE11/Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: string, containsPii: any) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                }
            }
        }
    },
    api: {
        getAvailableFAInfoAndChatName: "https://prod-172.westeurope.logic.azure.com:443/workflows/ec8f12cf85714001bf647f7c7a85f0bc/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
        callForHelp: "https://prod-203.westeurope.logic.azure.com:443/workflows/fdf79d623489473ca3b2e37df7dc2b79/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
        cancelCallForHelp: "https://prod-53.westeurope.logic.azure.com:443/workflows/0c4cde229a004b7c8e2553a2ef06c9ec/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
    },
    storageInformation: {
        accountName: 'firstaidersprodstorage',
        tableName: 'firstAidersList',
    },
    serviceAccountId: "78007fca-6c22-4424-a4ca-eb6af032d37b"
}