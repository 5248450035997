export default function getLocalStringDateAndTime(refreshDate: Date): string {
    const day = refreshDate.getDate();
    const month = refreshDate.getMonth() + 1;
    const year = refreshDate.getFullYear();

    const hours = refreshDate.getHours();
    const minutes = refreshDate.getMinutes();

    //day, month and minutes with leading zeros if needed
    const dateDay = (day < 10 ? '0' : '') + day;
    const dateMonth = (month < 10 ? '0' : '') + month;
    const dateMinutes = (minutes < 10 ? '0' : '') + minutes;

    const result = `${dateDay}.${dateMonth}.${year} — ${hours}:${dateMinutes}`;
    return result;
}