import { TranslationsKey, translations } from "../translations/translations";
import { useLanguage } from "./useLanguage";

export function useTranslate(key: TranslationsKey) {
    const language = useLanguage();
    const translated = translations[key][language];

    if(typeof translated === "string") {
        return translated;
    } else {
        //TODO: check if can be rewritten without span usage
        return translated.map((item, index) => <span key={index}>{item}</span>)
    }

}