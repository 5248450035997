import './App.css';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useEffect, useRef, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import Home from './components/Home/Home';
import { LanguageProvider } from './contexts/LanguageContext';
import ContactingFirstAiders from './components/ContactingFirstAiders/ContactingFirstAiders';
import { Route } from './enums/Routes';
import { callForHelp } from './api/callForHelp';
import { cancelCallForHelp } from './api/cancelCallForHelp';
import { CallState } from './enums/CallState';
import { useFirstAidersList } from './customHooks/useFirstAidersList';
import { getAvailableFAInfoAndChatName } from './api/getAvailableFAInfoAndChatName';
import { AvailableFAInfoAndChatNameResponseData } from './interfaces/AvailableFAInfoAndChatNameResponseData';
import { createChat } from './api/createChat';
import { CreateChatResponseData } from './interfaces/CreateChatResponseData';
import { v4 as uuidv4 } from 'uuid';
import { CallForHelpResponseData } from './interfaces/CallForHelpResponseData';
import { handleError } from './utils/handleError';

const floor = localStorage.getItem('floor') ?? null;

export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [firstAiders, lastRefreshedDate] = useFirstAidersList();

  const [currentRoute, setCurrentRoute] = useState<Route>(Route.Home);
  const [callForHelpData, setCallForHelpData] = useState<CallForHelpResponseData | null>(null);
  const [callState, setCallState] = useState<CallState | null>(null);
  const callingAccidentIdRef = useRef<null | string>(null);

  useEffect(() => {
    function handleLogin() {
      instance.loginRedirect(loginRequest).catch((error) => handleError(error, instance, loginRequest));
    }

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      handleLogin();
    }

  }, [isAuthenticated, instance, inProgress]);

  function handleCallForHelp() {
    if (!floor) return;

    const accidentId = uuidv4();
    setCallState(CallState.Calling);
    callingAccidentIdRef.current = accidentId;

    const defaultRequest = {
      ...loginRequest,
      account: accounts[0],
    }

    //TODO: refactor
    instance.acquireTokenSilent(defaultRequest)
      .then(response => response.accessToken)
      .then(accessToken => {
        return getAvailableFAInfoAndChatName(accessToken, floor, accidentId)
          .then((res: AvailableFAInfoAndChatNameResponseData) => {

            if (res.faAvailable === true) {
              const groupChatName = res.groupChatName!;
              const faIdArray = res.faIdArray!;
              const faMailArray = res.faMailArray!;

              return createChat(instance, accounts[0], groupChatName, faIdArray)
                .then((res: CreateChatResponseData) => {
                  const groupChatId = res.id;

                  if (groupChatId) {
                    callForHelp(accessToken, floor, accidentId, faMailArray, groupChatId)
                      .then((res: CallForHelpResponseData) => {
                        if (callingAccidentIdRef.current === accidentId) {
                          setCallForHelpData(res);
                          setCallState(res.someoneIsComing ? CallState.FirstAiderOnTheirWay : CallState.NoResponse);
                        }
                      })
                      .catch(error => handleError(error, instance, defaultRequest));
                  }
                })
            }
          })
      })
      .catch(error => handleError(error, instance, defaultRequest))

    setCurrentRoute(Route.ContactingFirstAiders);
  }

  function handleCancelCallForHelp() {
    const accidentId = callingAccidentIdRef.current;
    const defaultRequest = {
      ...loginRequest,
      account: accounts[0],
    };

    if (accidentId) {
      instance.acquireTokenSilent(defaultRequest)
        .then(response => response.accessToken)
        .then(accessToken => cancelCallForHelp(accessToken, accidentId))
        .catch(error => handleError(error, instance, defaultRequest));
    }

    setCurrentRoute(Route.Home);
    setCallForHelpData(null);
    callingAccidentIdRef.current = null;
    setCallState(null);
  }

  function handleOK() {
    setCurrentRoute(Route.Home);
    setCallForHelpData(null);
    callingAccidentIdRef.current = null;
    setCallState(null);
  }

  return (
    <div className="app">
      <AuthenticatedTemplate>
        <LanguageProvider>
          <>
            {
              currentRoute === Route.Home && <Home
                floor={floor}
                onCallForHelp={handleCallForHelp}
                firstAiders={firstAiders}
                lastRefreshedDate={lastRefreshedDate}
              />
            }
            {
              currentRoute === Route.ContactingFirstAiders && <ContactingFirstAiders
                firstAiderOnTheirWayEmail={(callForHelpData && callForHelpData.email) ? callForHelpData.email : null}
                firstAiders={firstAiders}
                callState={callState!}
                onCallForHelp={handleCallForHelp}
                onCancelCallForHelp={handleCancelCallForHelp}
                onOK={handleOK}
              />
            }
          </>
        </LanguageProvider>
      </AuthenticatedTemplate>
    </div>
  );
}