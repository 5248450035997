import "./EmergencyServicesNote.css";
import { useTranslate } from "../../customHooks/useTranslate";
import { TranslationsKey } from "../../translations/translations";

export default function EmergencyServicesNote() {

    return <div className="inline-flex flex-col mb-48">
        <h3 className="subtitle text-center">{useTranslate(TranslationsKey.CallEmergencyServices)}</h3>
        <div className="flex flex-row align-center justify-space-evenly">
            <div className="fs-32 fw-300 left">112</div>
            <div className="fs-32 fw-300 right">999</div>
        </div>
    </div>
}