import "./FirstAidersList.css";
import AvailableYes from "../../assets/icons/AvailableYes.svg";
import AvailableNo from "../../assets/icons/AvailableNo.svg";
import Accent from "../Accent/Accent";
import { FirstAider } from "../../interfaces/FirstAider";

interface Props {
    firstAiders: Array<FirstAider>
}

export function FirstAidersList({ firstAiders }: Props) {

    return (
        <div className="first-aiders-list">
            <Accent />
            <h2 className="table-title">List of First Aiders</h2>
            <h3 className="table-subtitle">Osoby wyznaczone do udzielania pierwszej pomocy</h3>
            <table className="first-aiders-list-table">
                <thead>
                    <tr>
                        <th>
                            NAME AND SURNAME
                            <br />
                            <span className="subheader">IMIĘ I NAZWISKO</span>
                        </th>
                        <th>
                            PHONE
                            <br />
                            <span className="subheader">TELEFON</span>
                        </th>
                        <th>E-MAIL</th>
                        <th>
                            AVAILABLE
                            <br />
                            <span className="subheader">DOSTĘPNY/DOSTĘPNA</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        firstAiders.map(fa => <tr key={fa.id}>
                            <td>{fa.name} {fa.surname}</td>
                            <td className="whitespace-nowrap">{fa.phone}</td>
                            <td>{fa.email}</td>
                            <td className="img-td">
                                {fa.availability
                                    ? <img src={AvailableYes} alt='First Aider is available' />
                                    : <img src={AvailableNo} alt='First Aider is unavailable' />
                                }
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}