import { environment as developmentConfig } from "./environment.dev";
import { environment as localDevelopmentConfig } from "./environment.localdev";
import { environment as productionConfig } from "./environment.prod";
import { environment as localProductionConfig } from "./environment.localprod";
import { Environment } from "../interfaces/Environment";

let environment: Environment;

switch (process.env.REACT_APP_ENV) {
    case "localdevelopment":
        environment = localDevelopmentConfig;
        break;
    case "development":
        environment = developmentConfig;
        break;
    case "localproduction":
        environment = localProductionConfig;
        break;
    case "production":
        environment = productionConfig;
        break;
    //TODO: update default
    default:
        environment = developmentConfig;
        break;
}

export default environment;