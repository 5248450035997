import environment from "./environments";

export const msalConfig = {
    auth: {
        clientId: environment.msal.auth.clientId,
        authority: environment.msal.auth.authority,
        redirectUri: environment.msal.auth.redirectUri,
    },
    cache: {
        cacheLocation: environment.msal.cache.cacheLocation,
        storeAuthStateInCookie: environment.msal.cache.storeAuthStateInCookie,
    },
    system: {
        loggerOptions: environment.msal.system.loggerOptions,
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: [`${environment.msal.auth.clientId}/.default`]
};

export const photoRequest = {
    scopes: ['User.ReadBasic.All'],
}

export const tableStorageRequest = {
    scopes: ['https://storage.azure.com/user_impersonation'],
};

export const chatRequest = {
    scopes: ["Chat.ReadWrite", "Chat.Create"],
}