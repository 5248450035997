export default function isDateToday(availabilityDate: Date) {
    const availabilityDateDay = availabilityDate.getUTCDate();
    const availabilityDateMonth = availabilityDate.getUTCMonth();
    const availabilityDateYear = availabilityDate.getUTCFullYear();

    const now = new Date();
    const nowDay = now.getUTCDate();
    const nowMonth = now.getUTCMonth();
    const nowYear = now.getUTCFullYear();

    if (availabilityDateDay === nowDay && availabilityDateMonth === nowMonth && availabilityDateYear === nowYear) {
        return true;
    } else {
        return false;
    }
}