
import { TableClient } from '@azure/data-tables';
import environment from "./environments";

//TODO: rewrite with TypeScript

export const getTableClient = async (accessToken, tableName) => {

    const credential = new StaticTokenCredential({
        token: accessToken,
    });

    const client = new TableClient(`https://${environment.storageInformation.accountName}.table.core.windows.net`, tableName, credential);
    return client;
}

export class StaticTokenCredential {
  constructor(accessToken) {
    this.accessToken = accessToken; 
  }

  async getToken() {
    return this.accessToken;
  }

}