import "./BreadcrumbNav.css";
import ArrowRight from "../../assets/icons/ArrowRight.svg";

export default function BreadcrumbNav() {

    return <div className="breadcrumb-nav flex flex-row align-center">
        <div className="tab">Home</div>
        <img src={ArrowRight} alt="" />
        <div className="tab">Contacting first aiders</div>
    </div>
}