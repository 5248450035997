import environment from "../environments";

export function cancelCallForHelp(accessToken: string, accidentId: string) {

    return fetch(environment.api.cancelCallForHelp,
    {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,  
        },
        method: "POST",
        body: JSON.stringify({
            accidentId: accidentId
        })
    })
    .then(res => {
        return res.json();
    })
    .catch(err => console.error(err));
};