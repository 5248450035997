import { useTranslate } from "../../customHooks/useTranslate";
import { ButtonType } from "../../enums/ButtonType";
import { TranslationsKey } from "../../translations/translations";
import BreadcrumbNav from "../BreadcrumbNav/BreadcrumbNav";
import Button from "../Button/Button";
import EmergencyServicesNote from "../EmergencyServicesNote/EmergencyServicesNote";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import Notification from "../Notification/Notification";
import Spinner from "../Spinner/Spinner";
import "./ContactingFirstAiders.css";
import { CallState } from "../../enums/CallState";
import NoResponse from "../../assets/icons/ABB_eBadge-2.svg";
import FirstAiderCard from "../FirstAiderCard/FirstAiderCard";
import { FirstAider } from "../../interfaces/FirstAider";
import { FirstAidersList } from "../FirstAidersList/FirstAidersList";

interface Props {
    firstAiders: Array<FirstAider>;
    firstAiderOnTheirWayEmail: string | null;
    callState: CallState;
    onCallForHelp: () => void;
    onCancelCallForHelp: () => void;
    onOK: () => void;
}

export default function ContactingFirstAiders({ firstAiders, firstAiderOnTheirWayEmail, callState, onCallForHelp, onCancelCallForHelp, onOK }: Props) {
    const firstAidersInTheOffice = firstAiders.filter(fa => fa.availability === true);
    const firstAiderOnTheirWay = firstAiderOnTheirWayEmail ? firstAiders.find(fa => fa.email === firstAiderOnTheirWayEmail) : null;

    const callingView = <>
        <Notification
            title={useTranslate(TranslationsKey.ContactingFirstAiders)}
            desc={useTranslate(TranslationsKey.ContactingFirstAiders_Hint)}
        />
        <Spinner />
        <EmergencyServicesNote />
        <Button type={ButtonType.MinorWhite} onClick={onCancelCallForHelp}>{useTranslate(TranslationsKey.Cancel)}</Button>
        <FirstAidersList firstAiders={firstAidersInTheOffice}></FirstAidersList>
    </>

    const noResponseView = <>
        <Notification
            title={useTranslate(TranslationsKey.NoResponse_Title)}
            desc={useTranslate(TranslationsKey.NoResponse_Content)}
        />
        <img className="mt-60 mb-72" src={NoResponse} alt='' />
        <EmergencyServicesNote />
        <div className="action-buttons-container">
            <Button type={ButtonType.MinorWhite} onClick={onCallForHelp}>{useTranslate(TranslationsKey.TryAgain)}</Button>
            <Button type={ButtonType.MinorRed} onClick={onOK}>{useTranslate(TranslationsKey.Ok)}</Button>
        </div>
        <FirstAidersList firstAiders={firstAidersInTheOffice}></FirstAidersList>
    </>

    const firstAiderOnTheirWayView = <div className="flex flex-col align-center">
        <Notification
            title={useTranslate(TranslationsKey.FirstAiderComing)}
            desc={useTranslate(TranslationsKey.WhatToDoWhileWaitingForFirstAider)}
        />
        <FirstAiderCard firstAider={firstAiderOnTheirWay!} />
        <Button type={ButtonType.MinorRed} onClick={onOK}>{useTranslate(TranslationsKey.Ok)}</Button>
    </div>

    return <div className="contacting-first-aiders flex flex-col align-center">
        <BreadcrumbNav />
        <LanguageToggle />
        {callState === CallState.Calling && callingView}
        {callState === CallState.NoResponse && noResponseView}
        {callState === CallState.FirstAiderOnTheirWay && firstAiderOnTheirWayView}
    </div>
}