import { FirstAider } from "../interfaces/FirstAider";

export function sortFirstAidersList(list: Array<FirstAider>): Array<FirstAider> {
    const availableFirstAidersWithPhoneNumbers: Array<FirstAider> = [];
    const availableFirstAidersWithNoPhoneNumbers: Array<FirstAider> = [];
    const notAvailableFirstAiders: Array<FirstAider> = [];

    list.forEach(fa => {
        if(fa.availability === true) {
            fa.phone ? availableFirstAidersWithPhoneNumbers.push(fa) : availableFirstAidersWithNoPhoneNumbers.push(fa);
        } else {
            notAvailableFirstAiders.push(fa);
        }
    });

    const sortedList = [...availableFirstAidersWithPhoneNumbers, ...availableFirstAidersWithNoPhoneNumbers, ...notAvailableFirstAiders];
    return sortedList;
}