import { createContext, useState } from "react";
import { Language } from "../enums/Language";

const initialLanguage = Language.English;

export const LanguageContext = createContext<Language>(initialLanguage);
export const ChangeLanguageContext = createContext<any>(null);

interface Props {
    children: JSX.Element | JSX.Element[];
}

export function LanguageProvider({ children }: Props) {
    const [lang, setLang] = useState(initialLanguage);

    return <LanguageContext.Provider value={lang}>
        <ChangeLanguageContext.Provider value={setLang}>
            {children}
        </ChangeLanguageContext.Provider>
    </LanguageContext.Provider>
}
