import { chatRequest } from "../authConfig";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { CreateChatRequestData } from "../interfaces/CreateChatRequestData";
import { ChatMember } from "../interfaces/ChatMember";
import environment from "../environments";
import { handleError } from "../utils/handleError";

export function createChat(instance: IPublicClientApplication, account: AccountInfo, groupChatName: string, faIdArray: Array<string>) {

    const serviceAccountMember: ChatMember = {
        "@odata.type": "#microsoft.graph.aadUserConversationMember",
        roles: ["owner"],
        "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${environment.serviceAccountId}')`
    };

    const FAChatMembers: Array<ChatMember> = faIdArray.map(id => ({
        "@odata.type": "#microsoft.graph.aadUserConversationMember",
        roles: ["owner"],
        "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${id}')`
    }));

    const chatMembers = [serviceAccountMember, ...FAChatMembers];

    const body: CreateChatRequestData = {
        chatType: "group",
        topic: groupChatName,
        members: chatMembers
    };

    const request = {
        ...chatRequest,
        account: account
    };

    return instance.acquireTokenSilent(request)
        .then(response => response.accessToken)
        .then(accessToken => {

            return fetch(`https://graph.microsoft.com/v1.0/chats`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`,
                    },
                    method: "POST",
                    body: JSON.stringify(body)

                })
                .then(res => res.json())
                .catch(error => console.error("chats error", error));
        })
        .catch(error => handleError(error, instance, request));

};