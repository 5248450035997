import { useTranslate } from "../../customHooks/useTranslate";
import { TranslationsKey } from "../../translations/translations";
import getLocalStringDateAndTime from "../../utils/getLocalStringDateAndTime";
import "./LastRefreshedDateSection.css";

interface Props {
    date: Date;
}

export default function LastRefreshedDateSection({ date }: Props) {
    const dateToDisplay = getLocalStringDateAndTime(date);

    return (
        <div className="last-refreshed-date">
            <h2 className="date-title">{useTranslate(TranslationsKey.LastRefreshedDate)}</h2>
            <p className="current-date">{dateToDisplay}</p>
        </div>
    )
}